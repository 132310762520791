.p-menubar .app-menu-20 .p-submenu-list {
    width: 20rem;
}

.p-menubar .app-menu-22 .p-submenu-list {
    width: 22rem;
}

.p-menubar .app-menu-25 .p-submenu-list {
    width: 25rem;
}

.p-menubar .app-menu-26 .p-submenu-list,
.p-menu.app-menu-26, .p-menu.app-menu-26 .p-menu-list {
    width: 26rem;
}

.layout-topbar .layout-topbar-logo {
    width: 200px;
}

.layout-main-container {
    padding: 8.5rem 2rem 1rem 4rem;
}

.layout-breadcrumb {
    padding: 5rem 2rem 0 2rem;
    background-color: var(--surface-50);
    position: fixed;
    width: 100%;
    z-index: 200;
}

.layout-breadcrumb .p-breadcrumb {
    background: transparent;
    border: none;
}

.layout-breadcrumb .p-megamenu {
    background: transparent;
    border: none;
    padding: 0;
}

.iam-scroll::-webkit-scrollbar {
    width: 8px;
}

.iam-scroll::-webkit-scrollbar-thumb {
    background-color: var(--primary-color);
    border-radius: 5px;
}

.template-preview {
    width: 100%;
    height: 1224px;
    border: none;
    background-color: white;
    overflow: hidden;
}

.p-tabview.tabview-panels-scroll .p-tabview-panels {
    padding: 0;
}

.p-tabview.tabview-panels-scroll .p-tabview-panels .iam-scroll {
    padding: 20px 5px;
}

.accordion-tab-header-action {
    position: absolute;
    top: 12px;
    right: 12px;
}

.p-disabled .accordion-tab-header-action * {
    cursor: pointer !important;
    pointer-events: auto;
}

.image-uploader {
    border: 2px dashed #ced0d4;
    border-radius: 10px;
    padding: 15px;
    margin: 18px;
    text-align: center;
}

.image-uploader .pi {
    font-size: 35px;
    color: var(--primary-color);
    cursor: pointer;
}

.image-uploader p {
    margin-bottom: 4px;
    color: var(--primary-color);
    cursor: pointer;
}

.divider-vertical {
    display: inline-flex;
    height: 30px;
    align-items: center;
    position: relative;
    justify-content: center;
    margin-top: 10px;
}

.divider-vertical:before {
    position: absolute;
    display: block;
    top: 0;
    left: 50%;
    height: 100%;
    content: "";
    border-left: 2px #dee2e6;
    border-left-style: solid;
}

.template-editor .p-dropdown .p-dropdown-trigger {
    width: 2rem;
}

.p-selectbutton.p-button-sm .p-button {
    padding: 0.65625rem 1.09375rem;
}

.template-editor .font-style .p-button {
    padding: 0.65625rem 0.7rem;
}

.p-button.p-button-icon-only.p-button-rounded.p-button-2rem {
    width: 2.4rem;
    height: 2.4rem;
}

.template-editor .p-colorpicker-preview.p-inputtext {
    padding: 1.2rem;
}

.p-editable-column, .quick-editable-column {
    background-color: #e4f1ff;
}

.template-editor-controls .p-orderlist-controls {
    display: none;
}

.template-editor-controls.p-orderlist .p-orderlist-list .p-orderlist-item {
    padding: 6px 5px 0 5px;
}

.template-editor-controls .col, .template-editor-controls .col-fixed {
    padding: 0.5rem 0.2rem;
}

.template-editor-controls .grid {
    padding: 0 5px;
}

.template-editor-controls .blank {
    height: 4px;
    background: #c7cbcf;
    padding: 0;
}

.p-orderlist .p-orderlist-list .p-orderlist-item.p-orderlist-droppoint-highlight {
    border-top: 3px solid var(--primary-color);;
}

.template-editor-controls .p-inputtext.p-disabled {
    pointer-events: auto;
}

.template-editor-controls .p-orderlist-list {
    min-height: unset;
    max-height: unset;
}

.p-orderlist-item[draggable="true"] {
    cursor: move;
}

.field label .required {
    color: red;
    margin-left: 5px;
}

.p-button.p-button-icon-only.p-button-rounded.p-button-sm {
    height: 2.5rem;
    width: 2.5rem;
    padding: 10px;
}

.table-actions .p-button.p-button-icon-only.p-button-rounded.p-button-sm {
    height: 2rem;
    width: 2rem;
    padding: 13px;
}

.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td.table-actions {
    padding: 0 0.5rem;
}

.p-datatable-table .p-datatable-tbody > tr:hover > td.table-actions {
    right: 0;
    position: sticky;
    background: inherit;
}

.p-autocomplete[aria-expanded='true'] > .p-button {
    pointer-events: none;
}

body.loading {
    pointer-events: none;
}

body.loading .app-progress-bar {
    display: block;
}

.app-progress-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    display: none;
}

.iam-toolbar {
    margin-right: 5px;
}

.iam-toolbar > a {
    border: 1px solid var(--primary-color);
}

.iam-toolbar > a > .p-menuitem-text,
.iam-toolbar > a > .p-menuitem-icon {
    color: var(--primary-color) !important;
}

.iam-toolbar.iam-toolbar-danger > a {
    border: 1px solid #dc3545;
}

.iam-toolbar.iam-toolbar-danger > a > .p-menuitem-text,
.iam-toolbar.iam-toolbar-danger > a > .p-menuitem-icon {
    color: #dc3545 !important;
}

.iam-toolbar.iam-toolbar-secondary > a {
    border: 1px solid #6c757d;
}

.iam-toolbar.iam-toolbar-secondary > a > .p-menuitem-text,
.iam-toolbar.iam-toolbar-secondary > a > .p-menuitem-icon {
    color: #6c757d !important;
}

.iam-toolbar.iam-toolbar-warning > a {
    border: 1px solid #ffc107;
}

.iam-toolbar.iam-toolbar-warning > a > .p-menuitem-text,
.iam-toolbar.iam-toolbar-warning > a > .p-menuitem-icon {
    color: #ffc107 !important;
}

.iam-toolbar.iam-toolbar-success > a {
    border: 1px solid #28a745;
}

.iam-toolbar.iam-toolbar-success > a > .p-menuitem-text,
.iam-toolbar.iam-toolbar-success > a > .p-menuitem-icon {
    color: #28a745 !important;
}

.iam-toolbar.iam-toolbar-info > a {
    border: 1px solid #17a2b8;
}

.iam-toolbar.iam-toolbar-info > a > .p-menuitem-text,
.iam-toolbar.iam-toolbar-info > a > .p-menuitem-icon {
    color: #17a2b8 !important;
}

.iam-toolbar.iam-toolbar-help > a {
    border: 1px solid #6f42c1;
}

.iam-toolbar.iam-toolbar-help > a > .p-menuitem-text,
.iam-toolbar.iam-toolbar-help > a > .p-menuitem-icon {
    color: #6f42c1 !important;
}

.layout-topbar .layout-topbar-logo img {
    height: 4.2rem;
}

.layout-topbar .layout-topbar-logo:focus {
    box-shadow: none;
}

.app-menu.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text,
.app-menu.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon,
.app-menu.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon,
.layout-breadcrumb .p-breadcrumb ul li .p-menuitem-link .p-menuitem-text,
.layout-breadcrumb .p-breadcrumb ul li .p-menuitem-link .p-menuitem-icon,
.layout-breadcrumb .p-breadcrumb ul li.p-breadcrumb-chevron {
    color: #0069b4;
}

.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td.p-selection-column,
.p-datatable.p-datatable-sm .p-datatable-thead > tr > th.p-selection-column {
    padding: 0;
}

.iam-data-table .p-datatable-scrollable .p-datatable-thead > tr > th.text-center,
.iam-data-table .p-datatable-scrollable .p-datatable-tbody > tr > td.text-center {
    justify-content: center;
}

.iam-data-table .p-datatable-resizable .p-datatable-thead > tr > th,
.iam-data-table .p-datatable-resizable .p-datatable-tfoot > tr > td,
.iam-data-table .p-datatable-resizable .p-datatable-tbody > tr > td {
    overflow: unset;
    white-space: unset;
}

.p-fluid input[type="file"] {
    width: 100%;
}

input[type="file"] {
    background: #ffffff;
    border: 1px solid #ced4da;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 3px;
    padding: 0.5rem 0.5rem;
}

.true-icon {
    color: var(--primary-color);
}

.false-icon {
    color: #dc3545;
}

.zzz {
    padding: 0.5rem 0.5rem 0.5rem 0;
    width: 3.33%;
}
/*.p-button.p-button-icon-only {*/
/*    margin-top: 1.7rem;*/
/*    height: 2.7rem*/
/*}*/

.yyy {
    padding: 0.5rem 0 0.5rem 0.5rem;
}
.xyxy {
    width: 21.67%;
    padding: 0.5rem 0 0.5rem 0.5rem;
}
.mt-button-info {
    margin-top: 1.7rem;
}
.p-inputtext {
    font-family: Inter, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 1rem;
    color: #495057;
    background: #ffffff;
    padding: 0.5rem 0.5rem;
    border: 1px solid #ced4da;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    appearance: none;
    border-radius: 6px;
}
.p-treeselect .p-treeselect-label {
    padding: 0.5rem 0.5rem;
}